import React from 'react'
import Home from './pages/home/home'
import Post from './pages/post/post'
import Sobre from './pages/about/sobre'
import Menu from './components/menu/menu'
import Rodape from './components/rodape/rodape'
import Fundos from './pages/fundosGestao/fundoGestao'
import Servico from './pages/servicos/servico'
import Contacto from './pages/contacto/contacto'


import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'



function App() {
    
    return (

        <Router>
            <React.Fragment>
                <Menu />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/sobre" component={Sobre} />
                    <Route path="/posts" component={Post} />
                    <Route path="/fundos_sobre_gestao" component={Fundos} />
                    <Route path="/servico" component={Servico} />
                    <Route path="/contacto" component={Contacto} />
                </Switch>
                <Rodape />

            </React.Fragment>
        </Router>

    )
}

export default App;