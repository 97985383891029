import React from 'react'
import pictures from '../../pictures'
import './fundoGestao.css'
import AOS from 'aos'
import '../../styles/aos-css/aos.css'

function FundoGestao() {

   AOS.init()
    return (
        <React.Fragment>

            <div className="row gestao">
                <header>
                    <div className="col l12 section-into s12 m12">

                        <div className="row">

                            <div className="col l7 box-picture01"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >
                                <img src={pictures.Vetor01} alt="vetor" className="vetor01 hide-on-small-and-down" />
                                <div className="cover"></div>

                                <img src={pictures.News3} alt="News" />




                            </div>

                            <div className="col l6 box-into offset-l5">

                                <hr className="barra" />

                                <p className="detalhe-into hide-on-small-and-down"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >

                                    Os fundos de pensões por<br /> nós concebidos e geridos <br /> são dirigidos a <br /> empresas .

                                </p>

                                <p className="detalhe-into hide-on-med-and-up"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >

                                    Os fundos de pensões por nós concebidos e geridos são dirigidos a  empresas .

                                </p>


                                <div className="box-desc"
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >



                                    <p className="detalhe-into-2 hide-on-small-and-down">
                                        Independentemente da forma que lhes dá origem e <br />
                                        constituem-se como patrimónios independentes, cujas <br />
                                        contribuições depositadas têm por objectivo único <br />
                                        financiar o plano de pensões previamente <br />
                                        estabelecido.
                                    </p>

                                    <p className="detalhe-into-2 hide-on-med-and-up">
                                        Independentemente da forma que lhes dá origem e 
                                        constituem-se como patrimónios independentes, cujas
                                        contribuições depositadas têm por objectivo único
                                        financiar o plano de pensões previamente
                                        estabelecido.
                                    </p>


                                    <img src={pictures.Vetor02} alt="vetor" className="vetor02" />

                                </div>




                            </div>


                        </div>

                    </div>

                </header>

                <div className="col l6 section-left s12">

                    <div className="row">

                        <div className="col l12"
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >

                            <hr className="barra hide-on-small-and-down" style={{ width: '80px' }} />
                            <p className="title-section hide-on-small-and-down">
                                Aplicando nossa filosofia, gerimos <br />
                                alguns dos maiores fundos de <br />
                                pensões de Angola. </p>

                                <p className="title-section hide-on-med-and-up">
                                Aplicando nossa filosofia, gerimos 
                                alguns dos maiores fundos de 
                                pensões de Angola. </p>

                        </div>

                        <div className="col l12"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >

                            <div className="row">

                                <div className="col l4 hide-on-small-and-down">

                                    <img src={pictures.Company} alt="our mission" className="icone-info" />

                                </div>
                                <div className="col l8 s12">


                                    <p className="describe-section hide-on-small-and-down">
                                        Partilhamos um conjunto de valores que <br />
                                        orientam o exercício da nossa actividade e <br />
                                        que influenciam  a forma  como trabalhamos <br />
                                        em equipa e interagimos com <br />
                                        os nossos clientes</p>

                                        <p className="describe-section hide-on-med-and-up">
                                        Partilhamos um conjunto de valores que 
                                        orientam o exercício da nossa actividade e 
                                        que influenciam  a forma  como trabalhamos 
                                        em equipa e interagimos com 
                                        os nossos clientes</p>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>

                <div className="col l6 section-right center s12">

                    <img src={pictures.Here} alt="man-bussines" className="man-bussines"
                        data-aos="fade-up" data-aos-easing="linear" data-aos-duration="{2500}" />

                </div>

                <div className="white col l12 s12 m12 reset box-first">

                    <section className="first-fundo">

                        <div className="row">

                            <div className="col l2 center s12"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >

                                <img src={pictures.Futuro} alt="icon-empresa" className="company" title="Futuro - Fundo de Pensões" style={{marginTop:'88px'}}/>

                            </div>

                            <div className="col l10 box-desc-first s12"
                                data-aos="fade-left"
                                data-aos-duration="1000"
                            >

                                <p className="position p-1 hide-on-small-and-down">01</p>

                                <p className="title-fundo">FUTURO<br />FUNDO DE PENSÕES</p>
                                <p className="desc-fundo">É um fundo fechado na modalidade de benefício definido destinado aos Antigos Combatentes que participaram na luta pela
                                    Independência Nacional. É um fundo de previdência complementar, pelo que cabe ao Associado do Fundo a responsabilidade de alocar
                                    os recursos para garantia dos pagamentos aos pensionistas, tal como previsto nos seus contratos de constituição e de gestão, e a
                                    entidade gestora, vinculada sob contrato, as responsabilidades seguintes:
                                </p>




                            </div>

                            <div className="col l12 s12 m12 geral-desc">

                                <div className="row">

                                    <div className="col l4 s12 padding-zero"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        <div className="c-fundo">



                                            <div className="col l12 box-desc-fundo">
                                                <p className="title"><i className="fa fa-calendar-check-o fa-2x" aria-hidden="true"></i> Gestão Actuarial</p>
                                            </div>

                                            <div className="col l12 s12 m12">

                                                <p className="desc-fundo-2">
                                                    Elaboração da Avaliação Actuarial do fundo com dados posicionados a 31 de Dezembro
                                                    de cada ano, na perspectiva de apurar o nível de financiamento do fundo e o seu compromisso
                                                    actuarial;
                                                </p>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col l4 s12 padding-zero"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        <div className="c-fundo c-g">



                                            <div className="col l12 box-desc-fundo">
                                                <p className="title"><i className="fa fa-money fa-2x" aria-hidden="true"></i>Gestão Fincanceira</p>
                                            </div>

                                            <div className="col l12 s12 m12">

                                                <p className="desc-fundo-2">
                                                    Realização do pagamento de benefícios de reforma, invalidez e sobrevivência do Futuro – Fundo de Pensões, rentabilização dos recursos e apresentação dos relatórios de prestação de contas devidamente auditados ao Associado do Fundo e a Entidade de Supervisão
                                                </p>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col l4 s12 padding-zero"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        <div className="c-fundo">



                                            <div className="col l12 box-desc-fundo">
                                                <p className="title"><i className="fa fa-line-chart fa-2x" aria-hidden="true"></i>Gestão Administrativa</p>
                                            </div>

                                            <div className="col l12 s12 m12">

                                                <p className="desc-fundo-2">
                                                    Realização do Cadastro, Prova de vida (2 vezes ao ano) e o Processamento das Pensões.
                                                </p>

                                            </div>

                                        </div>

                                    </div>








                                </div>

                            </div>

                        </div>

                    </section>


                </div>

                <div className="col l12 s12 m12 reset box-second">


                    <section className="second">
                        <p className="position p-2">02</p>
                        <img src={pictures.Grid} alt="grid" className="first-grid hide-on-small-and-down" />

                        <div className="row">

                            <div className="col l2 s12 center hide-on-med-and-up"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >

                                <img src={pictures.Multiplanos} alt="icon-empresa" className="company" title="Multiplanos 5 Estrelas"/>

                            </div>

                            <div className="col l2 s12 hide-on-small-and-down"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >

                                <img src={pictures.Multiplanos} alt="icon-empresa" className="company" title="Multiplanos 5 Estrelas"/>

                            </div>

                            <div className="col l8 box-desc-first "
                                data-aos="fade-left"
                                data-aos-duration="1000"
                            >

                                <p className="title-fundo">MULTIPLANOS 5 ESTRELAS</p>
                                <p className="desc-fundo">O Fundo de Pensões Multiplanos é um Fundo de Pensões Aberto, constituído e gerido
                                    por nós, que financia os seguintes  Planos de Pensões de 
                                    contribuição definida e de Benefício Definido, nomeadamente:
                                </p>



                            </div> 

                            <div className="col l12 s12 m12 plano">

                                <div className='row'>

                                <div className="col l4 s12 padding-zero"
                                        data-aos="fade-up"
                                        data-aos-duration="1000">
                                    

                                        <div className="c-fundo bg-white">



                                            <div className="col l12 box-desc-fundo ">
                                                <p className="title"><i className="fa fa-bookmark-o fa-2x" aria-hidden="true"></i>Plano A</p>
                                            </div>

                                            <div className="col l12 s12 m12">

                                                <p className="desc-fundo-2">
                                                    Plano de Benefício Definido Independente da Segurança Social: o
                                                    Fundo pagará uma pensão cuja taxa de formação será determinada pelo 
                                                    Associado, com uma pensão mínima e máxima a ser determinada pelo Associado.
                                                </p>

                                            </div>

                                        </div>

                                </div>

                                <div className="col l4 s12 padding-zero"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        <div className="c-fundo bg-white">



                                            <div className="col l12 box-desc-fundo ">
                                                <p className="title"><i className="fa fa-bookmark-o fa-2x" aria-hidden="true"></i>Plano B</p>
                                            </div>

                                            <div className="col l12 s12 m12">

                                                <p className="desc-fundo-2">
                                                    Plano de Benefício Definido Independente da Segurança Social: o 
                                                    Fundo pagará uma pensão cuja taxa de formação é igual a 2,5% por ano de 
                                                    serviço, com um valor de pensão máxima de 85% do último salário.
                                                </p>

                                            </div>

                                        </div>

                                </div>

                                <div className="col l4 s12 padding-zero"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        <div className="c-fundo bg-white">



                                            <div className="col l12 box-desc-fundo ">
                                                <p className="title"><i className="fa fa-bookmark-o fa-2x" aria-hidden="true"></i>Plano C</p>
                                            </div>

                                            <div className="col l12 s12 m12">

                                                <p className="desc-fundo-2">
                                                Plano de Contribuição Definida: A pensão estará de acordo com as contribuições e 
                                                com as rentabilidades geradas pela mesmas.
                                                </p>

                                            </div>

                                        </div>

                                </div>

                                    <div className='col l12 s12 m12 box-more-info padding-zero '
                                         data-aos="fade-up"
                                         data-aos-duration="1000"
                                    >

                                        <div>

                                            <p className="title">FORMAS DE REEMBOLSO</p>

                                            <p className="desc-fundo">Os Participantes poderão solicitar os reembolsos após a reforma (acima dos 60 anos), no caso de desemprego
                                            de longa duração ou por doença grave;
                                            <br />

                                            Os beneficiários do Fundo poderão escolher uma das seguintes formas de reembolso:

                                               
                                                <ul className='direitos'>
                                                    <li><b>1)</b>&nbsp; Revenda vitalíca;</li>
                                                    <li><b>2)</b>&nbsp; Totalidade do capital da conta individual;</li>
                                                    <li><b>3)</b>&nbsp; Um misto das duas formas anteriores</li>
                                                </ul>

                                            </p>

                                        </div>

                                </div>

                               
                                </div>

                            </div>

                        </div>

                    </section>



                </div>



                <div className="col l12 s12 m12 reset white box-three">

                    <section className="three">

                        <div className="row">

                            <div className="col l2 s12 padding-zero"></div>
                            <div className="col l4 s12 padding-zero"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >

                                <div className="three-box">
                                    <p className="position p-3">03</p>

                                    <p className="title-fundo">
                                        FUNDOS DE <br />
                                        PENSÕES DA SOGESTER

                                    </p>
                                    <p className="desc-fundo">Informações disponíveis no portal</p>

                                    <a href="https://www.gestaodefundos.com/Sogester/" target="blank">
                                        <button className="btn-acessar">ACESSAR O PORTAL</button>
                                    </a>

                                </div>

                            </div>
                            <div className="col l4 s12 padding-zero"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >

                                <div className="three-box">
                                    <p className="position p-3">04</p>

                                    <p className="title-fundo hide-on-small-and-down">
                                        FUNDOS DE <br />
                                        PENSÕES DO BANCO SOL

                                    </p>
                                    <p className="title-fundo hide-on-med-and-up">
                                        FUNDOS DE 
                                        PENSÕES DO BANCO SOL

                                    </p>
                                    <p className="desc-fundo">Informações disponíveis no portal</p>

                                    <a href="https://www.gestaodefundos.com/BancoSol/" target="_blank" rel="noreferrer">
                                        <button className="btn-acessar">ACESSAR O PORTAL</button>
                                    </a>

                                </div>

                            </div>

                        </div>

                    </section>

                </div>
                

                <div className='col l5 offset-l1 s12 m12 box-center'>
                    <h2 className='center-title'>Centro de Reclamações</h2>
                    <p className='title-goal'>Finalidade: <span>Atender Reclamações dos Participantes</span></p>
                    <p>Email: <span>reclamacoes@gestaodefundos.co.ao</span></p>
                    <p>Endereço: <span>Rua Direita do Patriota, travessa nº7, junto ao Banco Sol</span></p>
                    <p>Telefone: 923 660 100</p>
                </div>
                <div className='col l5 s12 m12 box-center-2'>
                <h2 className='center-title'>Provedor de Cliente </h2>
                <p>Email: <span>ncristovao.angocontas@gmail.com</span></p>
                    <p>Endereço: <span>Rua Direita do Patriota, travessa nº7, junto ao Banco Sol</span></p>
                    <p>Telefone: 939 383 491</p>
                </div>

               
            </div>
          

        </React.Fragment>
    )
}

export default FundoGestao